import { config } from 'config';
import i18n from 'i18n';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CRMTracker from 'utils/CRMTracker';
import useScript from 'utils/useScript';

const WIDGET_ROOT_EL = 'widget_osigu';
const scriptSrc = config.services.osigu.enrollment_url! as string;

export interface Props {
  onRegister: (event: any) => void;
  providerCredentials: {
    provider_name?: string;
    client_id?: string;
    client_secret?: string;
    time_zone?: string;
    customization: {
      observations?: string;
    };
  };
}

const OsiguRegisterDoctor: FC<Props> = ({
  onRegister,
  providerCredentials,
}) => {
  const profile = useSelector((state: any) => state.session.profile);
  const status = useScript(scriptSrc);
  const locale =
    i18n.language === 'pt-BR' ? i18n.language : i18n.language.split('-')[0];
  useEffect(() => {
    const container = document.getElementById(WIDGET_ROOT_EL);
    container?.addEventListener('onDoctorRegistered', (event: any) => {
      ConsultationsApiClient.chat
        .createPrescriptionProvider({
          provider: providerCredentials?.provider_name,
          data: event.detail,
        })
        .then(() => {
          onRegister(event.detail);
          CRMTracker.event('PrescriptionAccountCreated');
        });
    });
  }, []);

  useEffect(() => {
    if (status === 'ready') {
      (window as any).DoctorEnrollmentWidget.uniqueInstanceCreated = false;
      (window as any).DoctorEnrollmentWidget.createWidget({
        containerId: WIDGET_ROOT_EL,
        clientId: providerCredentials?.client_id,
        clientSecret: providerCredentials?.client_secret,
        primaryColor: '#4A1EA7',
        errorColor: '#FF014D',
        locale: locale, // 'es' | 'en' | 'pt' | "pr-BR"
        doctor: {
          givenName: profile.firstName,
          firstSurname: profile.lastName,
          emailAddress: profile.email,
          phoneNumber: profile.phone,
          sex: profile.gender?.toUpperCase(),
          medicalLicenseNumber: profile.college?.collegiate_number,
          timeZone: providerCredentials?.time_zone,
          countryCode: profile?.workPracticeCountry?.toUpperCase(),
        },
      });
    }
  }, [status]);

  return <div id={WIDGET_ROOT_EL}></div>;
};

export default OsiguRegisterDoctor;
