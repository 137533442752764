import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import DialingTone from 'containers/VideoCall/sound/wait.mp3';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import useTimeout from 'containers/VideoCall/useTimeout';
import CallView from 'containers/VideoCall/CallView';
import useCallSession from 'containers/VideoCall/useCallSession';
import { Publisher, Subscriber } from 'containers/VideoCall/CallStream';
import useListener from 'utils/useListener';
import { preloadScript } from 'opentok-react';
import { socket } from 'socket';

export const CallState = {
  DIALING: 'dialing',
  COMMUNICATING: 'communicating',
  FINISHED: 'finished',
  ON_CALL: 'on_call',
  REJECTED: 'rejected',
  DISCARDED: 'discarded',
  MISSED_CALL: 'missed_call',
  ERROR: 'error',
  PERMISSION_FAILED: 'permissions_failed',
  VIDEO_PERMISSION_FAILED: 'video_permissions_failed',
  RECONNECTING: 'reconnecting',
};

const Call = () => {
  const [callState, setCallState] = useState(CallState.DIALING);
  const jwtToken = useSelector((state) => state.session?.profile?.jwt);
  const { session, streams, videoActivated, uuid, title } = useCallSession({
    onCallError: () => setCallState(CallState.COMMUNICATING),
  });
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(videoActivated);
  const [receiverAudioEnabled, setReceiverAudioEnabled] = useState(true);
  const [receiverVideoEnabled, setReceiverVideoEnabled] =
    useState(videoActivated);
  const toneRef = useRef(new Audio(DialingTone));
  const otPublisherRef = useRef();
  const [devices, setDevices] = useState([]);
  const [videoSource, setVideoSource] = useState({});

  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices(devices.filter((device) => device.kind === 'videoinput'));
    setVideoSource(devices.find((device) => device.kind === 'videoinput'));
  };

  useEffect(() => {
    getDevices();
  }, []);

  const onCamaraSelect = (device) => {
    if (otPublisherRef) {
      const otPublisher = otPublisherRef.current.getPublisher();
      device.deviceId
        ? otPublisher.setVideoSource(device.deviceId).then().catch()
        : otPublisher.cycleVideo().then().catch();
      setVideoSource(
        devices.find((deviceItem) => deviceItem.deviceId === device.deviceId)
      ); // implement custom logic here
    }
  };

  useEffect(() => {
    ConsultationsApiClient.updateHeaders({
      Authorization: `Bearer ${jwtToken}`,
    });
  }, [jwtToken]);

  useListener({
    listener: 'beforeunload',
    dependencies: [callState],
    onEvent: (event) => {
      if (
        [CallState.ON_CALL, CallState.DIALING, CallState.RECONNECTING].includes(
          callState
        )
      ) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
  });

  useEffect(() => {
    socket?.on('call_rejected', ({ permissions: { audio, video } }) =>
      setCallState(
        !audio && !videoActivated
          ? CallState.PERMISSION_FAILED
          : (!audio || !video) && videoActivated
          ? CallState.VIDEO_PERMISSION_FAILED
          : CallState.REJECTED
      )
    );
  }, [socket]);

  const finish = () => {
    if (callState === CallState.DIALING) {
      ConsultationsApiClient.chat.rejectCall({
        uuid,
        video: videoActivated,
        audio: true,
      });
    } else {
      ConsultationsApiClient.chat.hangUpCall(uuid);
    }

    if (CallState.ERROR !== callState) {
      setCallState(
        CallState.DIALING === callState
          ? CallState.DISCARDED
          : CallState.FINISHED
      );
    }

    session?.disconnect();
  };

  useListener({
    listener: 'unload',
    dependencies: [callState],
    onEvent: finish,
  });

  useTimeout({
    timeout: 50000,
    callState,
    onTimeout: () => setCallState(CallState.MISSED_CALL),
  });

  useEffect(() => {
    if (CallState.MISSED_CALL === callState) {
      ConsultationsApiClient.chat.rejectCall({
        uuid,
        video: videoActivated,
        audio: true,
      });
    }
  }, [callState, uuid, videoActivated]);

  useEffect(() => {
    if (CallState.DIALING === callState) {
      toneRef.current.loop = true;
      toneRef.current.play().catch();
    } else {
      toneRef.current.pause();
    }
  }, [callState]);

  useEffect(() => {
    if (
      [
        CallState.FINISHED,
        CallState.PERMISSION_FAILED,
        CallState.VIDEO_PERMISSION_FAILED,
        CallState.REJECTED,
        CallState.MISSED_CALL,
      ].includes(callState)
    ) {
      session?.disconnect();
    }
  }, [session, callState]);

  useEffect(() => {
    if (CallState.ERROR === callState) {
      finish();
    }
  }, [callState]);

  useEffect(() => {
    if (streams?.length > 1) {
      setCallState(CallState.ERROR);
    }
  }, [streams]);

  return (
    <CallView
      publisher={
        <Publisher
          session={session}
          publishAudio={audioEnabled}
          publishVideo={videoEnabled}
          onChangeReceiverAudio={setReceiverAudioEnabled}
          otPublisherRef={otPublisherRef}
        />
      }
      otPublisherRef={otPublisherRef}
      devices={devices}
      videoSource={videoSource}
      onCamaraSelect={onCamaraSelect}
      subscriber={
        <Subscriber
          session={session}
          streams={streams}
          subscribeToVideo={videoActivated}
          onChangeReceiverVideo={setReceiverVideoEnabled}
          onConnected={() => setCallState(CallState.ON_CALL)}
          onDestroyed={(reason) =>
            setCallState(
              reason === 'networkDisconnected' || CallState.ERROR === callState
                ? CallState.ERROR
                : CallState.FINISHED
            )
          }
          onDisconnected={() => setCallState(CallState.RECONNECTING)}
          onError={() => setCallState(CallState.ERROR)}
        />
      }
      title={title}
      callState={callState}
      videoActivated={videoActivated}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      receiverAudioEnabled={receiverAudioEnabled}
      receiverVideoEnabled={receiverVideoEnabled}
      onFinish={finish}
      onToggleAudio={() => setAudioEnabled(!audioEnabled)}
      onToggleVideo={() => setVideoEnabled(!videoEnabled)}
    />
  );
};

export default preloadScript(Call);
