import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import { AccordeonHeader } from 'containers/Shared/components/Accordeon/AccordeonPanel';
import { useTranslation } from 'react-i18next';
import { Report } from 'api/reports.d';
import Loading from 'components/Loading';
import File from 'components/icons/File';
import { formatTime } from 'utils/date';
import { formatDate } from 'utils/dateFormatter';
import Download from 'components/Download';
import useDownloadReport from 'containers/DetailBar/PatientBar/components/Reports/useDownloadReport';
import { lastSent } from 'services/reports.legacy';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { useDispatch, useSelector } from 'react-redux';
import Tracker from 'utils/Tracking';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { usePortal } from 'containers/Shared/components/Portal';
import StartConsultationModal from 'containers/Consultations/StartConsultationModal';
import api from 'api';
import { chatConsultationStarted } from 'containers/Room/actions/JoinRoom.actions';

import DraftReport from 'containers/DetailBar/PatientBar/components/Reports/DraftReport';
import NewReportButton from 'containers/DetailBar/PatientBar/components/Reports/NewReportButton';
import { useGetAllReportsQuery } from 'services/reports';
import AutomaticReport from 'containers/DetailBar/PatientBar/components/Reports/AutomaticReport';
import { useParams } from 'react-router';

const ReportsPanel = ({
  badge,
  open,
  onOpen,
  onClose,
  hash,
  setTab,
  setIsReportCancelled,
  isReportCancelled,
}: any) => {
  const { t } = useTranslation();
  const roomId = useSelector((state: any) => state.console.current?.room_id);
  const { appointmentId }: { appointmentId?: string } = useParams();

  useEffect(() => {
    if (open) {
      Tracker.event('reports section click', {
        event_room_id: roomId,
      });
    }
  }, [open]);

  if (!open) {
    return (
      <AccordeonHandler badge={badge} onOpen={onOpen}>
        {t('sidebar__patient_reports_title')}
      </AccordeonHandler>
    );
  }

  return (
    <Container
      badge={badge}
      onClose={onClose}
      hash={hash}
      roomId={roomId}
      setTab={setTab}
      setIsReportCancelled={setIsReportCancelled}
      isReportCancelled={isReportCancelled}
      appointmentId={appointmentId}
    />
  );
};

const Container = ({
  badge,
  onClose,
  hash,
  roomId,
  setTab,
  setIsReportCancelled,
  isReportCancelled,
  appointmentId,
}: any) => {
  const { t } = useTranslation();
  const { handleChangeScreen } = useMedicalReport();
  const {
    data,
    isLoading: isLoadingReports,
    isError,
  } = useGetAllReportsQuery({ patient_hash: hash }, { skip: !hash });
  const { draftReport, publishedReports } = data || {};

  const reportsState = isLoadingReports
    ? 'loading'
    : !publishedReports?.length
    ? 'empty'
    : 'loaded';

  const inCall = useSelector((state: any) => state.waitingRoom.inCall);
  const { automaticReport, automaticReportStatus, isRecordable } = useSelector(
    (state: any) => state.medicalReports
  );
  const dispatch = useDispatch();
  const portal = usePortal();
  const current = useSelector((state: any) => state.console?.current);

  useEffect(() => {
    isError &&
      toast.error(
        <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
      );
  }, [isError]);

  const handleStartConsultation = () => {
    api.chat
      .start({ consultation_id: current?.current_chat_consultation?.id })
      .then(
        dispatch(
          chatConsultationStarted(current?.current_chat_consultation?.id)
        )
      );
  };

  const openCreateMedicalReport = () => {
    Tracker.event('reports create button click', {
      event_room_id: roomId,
    });
    if (current?.current_chat_consultation?.status === 'pending') {
      portal.open(
        <StartConsultationModal
          title={t('chat__consultation_start_report_modal_title')}
          description={t('chat__consultation_start_report_modal_description')}
          onClose={() => portal.close('start_consultation')}
          consultation={current?.current_chat_consultation}
          costumerName={current?.customer?.name}
          onConfirm={() => {
            handleStartConsultation();
            portal.close('start_consultation');
          }}
        />,
        'start_consultation'
      );
    } else {
      if (inCall) {
        setTab('report');
        return;
      }
      handleChangeScreen('report');
      return;
    }
  };

  const shouldShowAutomaticReport =
    automaticReport &&
    isRecordable &&
    !isReportCancelled &&
    appointmentId &&
    ['loading', 'finished'].includes(automaticReportStatus);

  return (
    <div className="p-4 border-b border-separators">
      <AccordeonHeader badge={badge} onClose={onClose}>
        {t('sidebar__patient_reports_title')}
      </AccordeonHeader>
      {
        {
          loading: <Loading />,
          empty: (
            <div className="my-4">
              {shouldShowAutomaticReport ? (
                <AutomaticReport
                  status={automaticReportStatus}
                  consultationId={appointmentId}
                  consultationType="appointment"
                  setIsReportCancelled={setIsReportCancelled}
                />
              ) : draftReport ? (
                <DraftReport report={draftReport} />
              ) : (
                <NewReportButton onClick={openCreateMedicalReport} />
              )}
            </div>
          ),
          loaded: (
            <>
              <div className="my-4">
                {shouldShowAutomaticReport ? (
                  <AutomaticReport
                    status={automaticReportStatus}
                    consultationId={appointmentId}
                    setIsReportCancelled={setIsReportCancelled}
                    consultationType="appointment"
                  />
                ) : draftReport ? (
                  <DraftReport report={draftReport} />
                ) : (
                  <NewReportButton onClick={openCreateMedicalReport} />
                )}
              </div>
              <ul className="flex flex-col gap-4">
                {publishedReports &&
                  [...publishedReports]
                    .sort(lastSent)
                    .map((report) => (
                      <Item
                        key={report.uuid}
                        report={report}
                        patientHash={hash}
                        roomId={roomId}
                      />
                    ))}
              </ul>
            </>
          ),
        }[reportsState]
      }
    </div>
  );
};

const Item = ({
  report,
  patientHash: patient_hash,
  roomId,
}: {
  report: Partial<Report>;
  patientHash: string;
  roomId: number;
}) => {
  const { state, download } = useDownloadReport({
    reportUuid: report.uuid!,
    patientHash: patient_hash,
  });
  const date = report.created_at;

  const handleDownload = () => {
    Tracker.event('reports download button click', {
      event_room_id: roomId,
    });
    download();
  };

  return (
    <li className="flex items-center gap-2 text-xs">
      <div className="grid flex-shrink-0 w-10 h-10 rounded-full bg-blue-light place-items-center">
        <File className="w-4 h-4 text-blue" />
      </div>
      <div className="flex flex-col gap-1">
        <time dateTime={report.created_at} className="font-medium">
          {`INF · ${formatDate(date)} · ${formatTime(date)}h`}
        </time>
        <p className="font-medium leading-4 text-gray-medium">
          {report?.professional?.name}
        </p>
      </div>
      <Download
        className="w-10 h-10 ml-auto"
        state={state}
        onClick={handleDownload}
      />
    </li>
  );
};

export default ReportsPanel;
